import React, { useState } from "react"
import { css } from "@emotion/react"
import SEO from "../../components/bseo"
import BookLayout from "../../components/BookLayout"

const Contact = () => {
  return (
    <BookLayout>
      <SEO title="Contact"></SEO>
      <div>
        <h1
          css={css`
            text-align: center;
            margin-top: 20px !important;
          `}
        >
          Contact
        </h1>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 10px;
          text-align: left;
        `}
      >
        <p
          css={css`
            padding: 0 20px;
            line-height: 1.5 !important;
          `}
        >
          Hey there 👋🏾, I'm Dmitri. You can email me at dkb@dkb.fyi, or DM me on
          twitter (
          <a target="_blank" href="https://twitter.com/dkb868">
            @dkb868
          </a>
          ), if you have any questions, concerns, thoughts, or ideas.
        </p>
      </div>
    </BookLayout>
  )
}

export default Contact
