import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/react"
const BookNav = ({}) => (
  <ul
    css={css`
      list-style: none;

      padding: 15px 20px;
      background-color: #fff;
      width: 80%;

      @media screen and (max-width: 705px) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 0;
        padding-right: 0;
      }
      margin: 0 auto;
      li {
        display: inline;
        margin: 0;
        margin-right: 30px;
        font-size: 20px;
        @media screen and (max-width: 600px) {
          margin-right: 0;
          font-size: 24px;
        }
      }

      a {
        color: #000;
      }
    `}
  >
    <li>
      <Link
        css={css`
          margin-right: 100px;
          font-size: 24px;
          text-decoration: none;
          @media screen and (max-width: 705px) {
            margin-right: 0;
          }
        `}
        to="/books"
      >
        <strong>Blog Books</strong>
      </Link>
    </li>
    <li>
      <Link to="/books/store">Bookstore</Link>
    </li>
    <li>
      <Link to="/books/request">Request Book</Link>
    </li>
    <li>
      <Link to="/books/contact">Contact</Link>
    </li>
  </ul>
)

export default BookNav
