import React from "react"
import { Helmet } from "react-helmet"

const SEO = ({ title, description }) => {
  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      titleTemplate="%s | Blog Books"
      meta={[
        {
          name: `description`,
          content:
            description ||
            "Book chapters delivered to your inbox on your schedule.",
        },
      ]}
    ></Helmet>
  )
}

export default SEO
