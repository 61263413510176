import React from "react"
import { css } from "@emotion/react"
import BookNav from "./BookNav"
import SEO from "./bseo"
import "./reset.css"

const Layout = ({ children }) => {
  return (
    <div
      css={css`
        * {
          box-sizing: border-box;
        }
      `}
    >
      <SEO />
      <div
        css={css`
          h1,
          h2,
          h3,
          h4,
          ul {
            font-family: "Source Serif Pro", serif;
          }

          h1 {
            font-weight: 600;
            font-size: 50px;
            line-height: 1.13;
            margin: 0;
            @media screen and (max-width: 1000px) {
              font-size: 40px;
            }
          }

          h2 {
            font-weight: 600;
            font-size: 40px;
          }

          h3 {
            font-weight: 300;
            font-size: 30px;
            margin: 5px 0;
          }

          h4 {
            font-weight: 400;
          }

          p {
            font-family: "Montserrat", sans-serif;
            font-size: 20px;
            line-height: 1.3;
            max-width: 600px;
            margin: 10px 0;
            @media screen and (max-width: 800px) {
              font-size: 24px;
            }
          }

          & > section {
            padding: 100px 50px;
            width: 100%;

            @media screen and (max-width: 600px) {
              padding-top: 50px;
              width: 100%;
            }
          }
          & > section > div {
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 1000px;
            margin: 0 auto;

            @media screen and (max-width: 1200px) {
              width: 1000px;
            }
            @media screen and (max-width: 1000px) {
              width: 700px;
            }

            @media screen and (max-width: 800px) {
              width: 80%;
              flex-direction: column;
            }

            h2 {
              margin: 0;
            }

            img {
              margin-left: 50px;
              @media screen and (max-width: 800px) {
                margin-top: 50px;
                margin-left: 0;
              }
            }
            a {
              color: #323232;
              font-family: "Montserrat", sans-serif;
              font-size: 20px;
              @media screen and (max-width: 800px) {
                font-size: 24px;
              }
            }
          }
          & > section > div > div:first-child {
            display: flex;
            flex-direction: column;
            width: 500px;
            @media screen and (max-width: 1200px) {
              width: 400px;
            }
            @media screen and (max-width: 1000px) {
              width: 300px;
            }
            @media screen and (max-width: 800px) {
              width: 100%;
            }
          }
          & > section > div > div:last-child {
            width: 600px;
            @media screen and (max-width: 1200px) {
              width: 500px;
            }
            @media screen and (max-width: 1000px) {
              width: 400px;
            }
            @media screen and (max-width: 800px) {
              width: 100%;
            }
          }

          .bookshelf-preview {
            display: block;
          }

          .bookshelf-preview-image-container {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media screen and (max-width: 800px) {
              flex-direction: column;
              align-items: center;
            }

            img {
              width: 200px;
              margin-top: 25px;
              @media screen and (max-width: 800px) {
                width: 80%;
                min-width: 200px;
              }
            }
            a {
              margin-left: 50px;
              width: min-content;
              @media screen and (max-width: 800px) {
                margin-left: 0;
                width: min-content;
              }
            }
            img:first-child,
            a:first-child {
              margin-left: 0;
            }
          }

          .faq {
            display: block;

            .question {
              margin-top: 25px;
            }

            p {
              max-width: 800px;

              @media screen and (max-width: 800px) {
                width: 100%;
              }
              @media screen and (max-width: 600px) {
                width: 100%;
                font-size: 18px;
              }
            }
            a {
              @media screen and (max-width: 600px) {
                font-size: 18px;
              }
            }
          }

          .hero {
            padding-top: 10px;

            h1 {
              width: 400px;

              @media screen and (max-width: 1000px) {
                width: 300px;
              }
              @media screen and (max-width: 800px) {
                width: 100%;
                text-align: center;
              }
            }
          }
        `}
      >
        <BookNav />
        {children}
      </div>
    </div>
  )
}

export default Layout
